import React from 'react';
import './about.css';

const About = () => {
  return (
    <div className="about-container">
      <h2>About Ozzar International</h2>
      <p>
        Ozzar International is committed to serving customers in the realms of Bedding, Home Improvement, Towels, and Kitchen accessories. We offer competitively priced, quality products designed to meet your daily needs. Our products are supported by top-notch customer service and swift shipping, ensuring our customers' satisfaction with every purchase from Ozzar International.
      </p>
      <p>
        Our dedication to customer satisfaction has led to significant growth in our customer base over the years. As a burgeoning company, we prioritize providing exceptional customer service and affordable, high-quality products that enhance our customers' quality of life.
      </p>
      <h3>Ozzar International at a Glance</h3>
      <ul>
        <li>Over 100 million products sold</li>
        <li>More than 250 million product views</li>
        <li>Serving over 75,000 customers daily</li>
        <li>Boasting a customer base of over 15 million worldwide</li>
        <li>Global presence across 5 continents and spanning more than 15 countries</li>
        <li>Establishing a presence in various marketplaces including Amazon, Walmart, Shopify, and eBay</li>
      </ul>
      <h3>Industries We Serve</h3>
      <h4>Healthcare Industry</h4>
      <p>Ozzar International offers hypoallergenic products designed to equip healthcare and rehabilitation facilities with the necessary items to meet customer needs.</p>
      <h4>Hospitality Industry</h4>
      <p>Our diverse range of products in Bedding, Home Improvement, and Kitchen categories caters to hotels, motels, vacation rentals, and kitchens, providing products to suit all needs and sizes.</p>
      <h4>Restaurant Industry</h4>
      <p>We provide premium-quality products for restaurants, including Aprons, Kitchenware, Dinnerware, Bamboo Accessories, and Table Accessories, all made to the highest standards and optimized for shipping.</p>
      <h4>Salon & Spa</h4>
      <p>With a wide variety of personal care products, including bathrobes, salon towels, bleach-proof towels, nail care tools, and premium beauty and personal care items, we aim to create a relaxing experience for salon and spa customers.</p>
      <h4>Fitness & Healthcare</h4>
      <p>Our range of cardio-based products is perfect for gyms, fitness centers, trainers, and coaches, offering competitive prices on high-quality fitness equipment.</p>
      <h3>Our Core Values</h3>
      <h4>Customer Care</h4>
      <p>At Ozzar International, we are dedicated to providing exceptional customer service, ensuring that all our customers' needs are met through a single platform.</p>
      <h4>Improving Lives</h4>
      <p>Through our products and services, we strive to enhance the lives of our customers, offering hassle-free shopping experiences and products designed to make life easier.</p>
    </div>
  );
}

export default About;
