import React from 'react';
import './rating.css'; // Assuming you will create a separate CSS file for styling

const RatingStars = ({ rating }) => {
  // Create an array of stars
  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <span key={i} className={i <= rating ? "star filled" : "star"}>
        &#9733; {/* Star character */}
      </span>
    );
  }

  return <div className="rating">{stars}</div>;
};

export default RatingStars;
