import React from 'react';
import './footer.css'; // Import footer styles

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-column">
        <div className="footer-logo">
          <img src="http://ozzar-int.com/images/logo.png" alt='Ozzar International logo' />
        </div>
        <div className="footer-about">
          <p>Ozzar International is committed to providing customers with a wide range of products, 
            including bedding, home improvement items, towels, and kitchen accessories.</p>
        </div>
      </div>
      <div className="footer-column">
        <h5>About</h5>
        <ul>
          <li><a href="/about">About Us</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h5>Categories</h5>
        <ul>
          <li><a href="/towels">Towels</a></li>
          <li><a href="/kitchen">Kitchen</a></li>
          <li><a href="/personal-care">Personal Care</a></li>
          <li><a href="/bedding">Bedding</a></li>
          <li><a href="/barber-supplies">Barber Supplies</a></li>
        </ul>
      </div>
      <div className="footer-column">
        <h5>Support</h5>
        <ul>
          <li><a href="/contact">Contact Us</a></li>
          <li><a href="/register">Product Registration</a></li>
          <li><a href="/customer-support">Customer Support</a></li>
          <li><a href="/privacy-policy">Privacy Policy</a></li>
          <li><a href="/shipping-policy">Shipping Policy</a></li>
        </ul>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Ozzar International. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
