// Carousel.js

import React from 'react';
import './carousel.css'; // Import styles for carousel
import CarouselItem from './carouselitem/carouselItem';
import bedSets from '../../assets/images/bed_sets_2.jpg'
import barberShop from '../../assets/images/beauty_salon.jpg'
import towelSets from '../../assets/images/towelSets_2.jpg'

const carouselData = [
    {
        src: barberShop,
        title: 'Elevate Your Style & Craft',
        caption: 'Premium Barber Supplies for Professionals and Home Stylists Alike',
        isActive: true
    },
    {
        src: bedSets,
        title: 'Sleep in Comfort and Style',
        caption: 'Luxurious Bed Sheets for Restful Nights and Cozy Mornings',
        isActive: false
    },
    {
        src: towelSets,
        title: 'Wrap Yourself in Luxury',
        caption: 'Versatile Towels for Bath, Beach, Gym, and Home',
        isActive: false
    }
];

const Carousel = () => {
    return (
        <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {carouselData.map((item, index) => (
                    <button key={index} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={item.isActive ? 'active' : ''} aria-current={item.isActive ? 'true' : ''} aria-label={`Slide ${index + 1}`}></button>
                ))}
            </div>
            <div className="carousel-inner">
                {carouselData.map((item, index) => (
                    <CarouselItem key={index} {...item} />
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
}

export default Carousel;
