import React from 'react';
import Carousel from '../carousel/carousel';
import ProductSection from '../products/products';

const Home = () => {
  return (
    <div>
      <Carousel />
      <ProductSection />
    </div>
  );
}

export default Home;
