import React from 'react';
import RatingStars from '../../Rating/rating';
import './productcard.css'; // Path to your CSS file for styling

const ProductCard = ({ product , onImageClick }) => {
      return (
        <div className="col-md-4">
            <div className="product">
                <img src={product.image} alt={product.title} onClick={() => onImageClick(product)} />
                <div className="product-info">
                    <h4>{product.title}</h4>
                    <RatingStars rating={product.rating} />
                    <p>${product.price}</p>
                    <button className="btn btn-primary">Add to Cart</button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;