import React from "react";
import './shipping.css';


const Shipping = ()=>{
    return(
        <div className="shipping-container">
            <h1>Shipping & Return Policy</h1>
                <h2>Shipping Policy</h2>
                <p>
                    We provide ground shipping via UPS and deliver within 5-6 business days depending upon your location. We have negotiated the best possible rates with UPS so you always get the best shipping rates.
                We have different warehouses all across the USA and that is what allow us to ship our products at the most competitive rates for our customers. All our products are stored and shipped from the nearest location to a buyer to ensure that the shipping cost and time is optimal.
                Please note that we only ship in the contiguous United States.
                </p>
                <p>
                    Ozzar International
                    26 Cheryl Lane
                    Millstone, NJ 08510
                </p>
                <h2>Return Policy</h2>
                <p>We have very easy to understand return policy:</p>
                <ul>
                    <li>We offer 30 days return with no restocking fees</li>
                    <li>Returned items should be unused, unopened and in the original packaging</li>
                    <li>If a wrong item is delivered to you, we pay the return shipping</li>
                    <li>If the item delivered is correct, you pay the return shipping</li>
                </ul>
                <h3>Return Procedure</h3>
                <p>Below is the procedure for returns:</p>
                <ol>
                    <li>Contact us via our return request form before returning the product and we will send you labels for return shipping</li>
                    <li>Mail the items using the return labels and we will notify you once we receive the items</li>
                    <li>After the items have been received and inspected, we will process your refund and the amount will be applied to the original method of payment within 5 business days</li>
                    <li>If applicable, return shipping costs will be deducted from the refunded amount</li>
                </ol>
                <h3>Late or Missing Refunds (If Applicable)</h3>
                <p>
                    If you haven't received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted.
                Next contact your bank. There is often some processing time before a refund is posted. If you've done all of this and you still have not received your refund yet, please contact us at <a href="mailto:info@ozzar-int.com">info@ozzar-int.com</a>.
                </p>
        </div>
    )
}

export default Shipping;