import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/header';
import Home from './components/home/home';
import Footer from './components/footer/footer';
import Register from './components/register/register';
import Contact from './components/contact/contact';
import About from './components/about/about';
import PrivacyPolicy from './components/privacy/privacy';
import Success from './components/success/success';
import Shipping from './components/shipping/shipping';

function App() {
  return (
    <div className='App'>
      <Router>
        <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/register" element={<Register />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/customer-support" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/shipping-policy" element={<Shipping />} />
            <Route path='/success' element={<Success />} />
          </Routes>
          <Footer />
      </Router>
    </div>
  );
}

export default App;
