import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://ozzar-int.com/api' : 'https://ozzar-int.com/api';

const apiService = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const productService = {
  registerProduct: async (formData) => {
    try {
      const response = await apiService.post('/product_registeration.php', formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default productService;
