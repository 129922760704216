import React from 'react';
import './error.css'; // Make sure to create this CSS file

const ErrorMessage = ({ message, onDismiss }) => {
    return (
        <div className="error-message">
            {message}
            <button onClick={onDismiss} className="dismiss-button">X</button>
        </div>
    );
};

export default ErrorMessage;