import React from 'react';
import './contact.css'; // Import CSS for contact page

const Contact = () => {
    return (
        <div className="container mt-5">
            <h1>Customer Support</h1>
            <p>Product Usage and Product Related Issues</p>
            <p>In cases where products require manuals, we send you an additional usage document. Please refer to that document to ensure that you are using the products for their intended purposes and as directed.</p>
            <p>Ensure that you properly follow the washing and drying instructions of our products so that they do not get damaged.</p>
            <p>Some products incorporate a sharp blade edge and are NOT INTENDED to be used on Animals.</p>
            <p>Our towel product requires special care and therefore, care instructions should be followed.</p>
            <h2>Customer Support</h2>
            <p>If you are not sure about how to utilize your product, then you can contact our friendly support team for a quick solution to your problem.</p>
            <p>Email us at <a href="mailto:info@ozzar-int.com">info@ozzar-int.com</a> or contact us via our <a href="/contact">contact us page</a>.</p>
            <p>While contacting us, please make sure to provide us the following information:</p>
            <ul>
                <li>Order Confirmation/Order ID</li>
                <li>Product ASIN (in case of multiple orders)</li>
            </ul>
            <p>We hope to have you on board with us as we look to change the dynamics of online retail around the globe.</p>
            <h2>Shipping & Returns</h2>
            <p>Please check our shipping and returns policy <a href="/shipping-and-returns">here</a>.</p>
        </div>
    );
}

export default Contact;
