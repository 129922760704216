import React, { useState, useRef } from 'react';
import productService from '../../services/api/apiService';
import './register.css'; // Import CSS for registration form
import Spinner from '../shared/components/spinner/spinner';
import ErrorMessage from '../shared/components/error/error';

const Register = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        orderId: '',
        marketplace: '',
        updates: false,
        phone: '',
        isHuman: '', // CAPTCHA answer
    });

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [showError, setShowError] = useState(false);
    const captchaRef = useRef(null); // Reference to CAPTCHA field

    const correctAnswer = 5; // CAPTCHA correct answer (2 + 3)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // CAPTCHA validation
        if (parseInt(formData.isHuman, 10) !== correctAnswer) {
            setError('CAPTCHA verification failed. Please try again.');
            setShowError(true);
            setIsLoading(false);
            window.scrollTo({
                top: captchaRef.current.offsetTop, // Scroll to CAPTCHA field
                behavior: 'smooth',
            });
            return;
        }

        try {
            const response = await productService.registerProduct(formData);
            console.log(response); // Handle successful response
            if (response && response.statusCode === 'success') {
                setIsLoading(false);
                window.location.href = '/success';
            } else if (response && response.statusCode === 'error') {
                setError(response.message);
                setShowError(true);
                setIsLoading(false);
            } else {
                setError('Something went wrong, please try again.');
                setShowError(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error:', error); // Handle error
            setError(error.message);
            setShowError(true);
            setIsLoading(false);
        }
    };

    const dismissError = () => {
        setShowError(false);
        setError('');
    };

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : value,
        });
    };

    return (
        <div>
            {isLoading && <Spinner />}
            {showError && <ErrorMessage message={error} onDismiss={dismissError} />}

            <div className="container mt-5">
                <h2>Product Registration</h2>
                <form id="registrationForm" onSubmit={handleSubmit}>
                    {/* Form fields */}
                    <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">First Name *</label>
                        <input type="text" className="form-control" id="firstName" value={formData.firstName} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">Last Name</label>
                        <input type="text" className="form-control" id="lastName" value={formData.lastName} onChange={handleChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email Address *</label>
                        <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Phone *</label>
                        <input type="phone" className="form-control" id="phone" value={formData.phone} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="address" className="form-label">Address *</label>
                        <input type="text" className="form-control" id="address" value={formData.address} onChange={handleChange} required />
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <label htmlFor="city" className="form-label">City *</label>
                            <input type="text" className="form-control" id="city" value={formData.city} onChange={handleChange} required />
                        </div>
                        <div className="col">
                            <label htmlFor="state" className="form-label">State *</label>
                            <input type="text" className="form-control" id="state" value={formData.state} onChange={handleChange} required />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="zipCode" className="form-label">Zip Code *</label>
                        <input type="text" className="form-control" id="zipCode" value={formData.zipCode} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="orderId" className="form-label">Order Id *</label>
                        <input type="text" className="form-control" id="orderId" value={formData.orderId} onChange={handleChange} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor='marketplace' className="form-label">Marketplace Name *</label>
                        <select className="form-select" id='marketplace' name="marketplace" required onChange={handleChange}>
                            <option selected disabled value="">Choose...</option>
                            <option value="Amazon">Amazon</option>
                            <option value="eBay">eBay</option>
                            <option value="Etsy">Etsy</option>
                            <option value="Wayfair">Wayfair</option>
                            <option value="Walmart">Walmart</option>
                        </select>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="updates" checked={formData.updates} onChange={handleChange} />
                        <label className="form-check-label" htmlFor="updates">Send me latest products and updates</label>
                    </div>
                    <div className="mb-3" ref={captchaRef}>
                        <label htmlFor="isHuman" className="form-label">Add 2 + 3 *</label>
                        <input type="text" className="form-control" id="isHuman" value={formData.isHuman} onChange={handleChange} required />
                        <small className="form-text text-muted">Please solve this simple math problem to help us prevent spam submissions.</small>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default Register;
