// CarouselItem.js
import React from 'react';
import './styles.css'; // Import styles specific to CarouselItem

const CarouselItem = ({ src, title, caption, isActive }) => {
    return (
        <div className={`carousel-item ${isActive ? 'active' : ''}`}>
            <img src={src} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-none d-md-block">
                <h1 className='carousel_heading'>{title}</h1>
                <p className='carousel_p'>{caption}</p>
            </div>
        </div>
    );
}

export default CarouselItem;
