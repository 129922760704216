import React from 'react';
import './success.css'; // Adjust the path according to your file structure

const Success = () => {
  return (
    <div className="success-container">
      <h1 className="success-title">Registration Successful!</h1>
      <p className="success-message">Your product has been successfully registered with Ozzar International.</p>
      <p className="success-message">Please check your email for the confirmation and further details. Make sure to check your
      junk folder as sometimes the emails are sent there.</p>
      <a href="/" className="homepage-link">Go to Homepage</a>
    </div>
  );
};

export default Success;
