// ProductsSection.jsx
import React, { useState } from 'react';
import ProductCard from './productCard/productcard';
import ProductModal from '../modals/productmodal/productmodal';
import './products.css'; // Your CSS file for styling ProductsSection

const products = [
    {
      id: 1,
      sku: "BR-001",
      title: "Barber Razor (Berlin)",
      price: 14.99,
      image: "https://m.media-amazon.com/images/I/71KHJRsE5jL._SL1500_.jpg",
      description: "High-quality barber razor designed for precision and comfort. Berlin model.",
      rating: 4
    },
    {
      id: 2,
      sku: "BR-002",
      title: "Barber Razor (Istanbul)",
      price: 14.99,
      image: "https://m.media-amazon.com/images/I/71YUzR-Q-QL._SL1500_.jpg",
      description: "Ergonomic barber razor for professional use. Istanbul model.",
      rating: 4.5
    },
    {
      id: 3,
      sku: "BR-003",
      title: "Barber Razor (London)",
      price: 14.99,
      image: "https://m.media-amazon.com/images/I/71k45adVScL._SL1500_.jpg",
      description: "Sleek design with exceptional grip. London model.",
      rating: 5
    },
    {
      id: 4,
      sku: "BR-004",
      title: "Barber Razor (Manhattan)",
      price: 14.99,
      image: "https://m.media-amazon.com/images/I/71hOpdARzXL._SL1500_.jpg",
      description: "Manhattan model razor combines classic styling with modern performance.",
      rating: 4.2
    },
    {
      id: 5,
      sku: "SC-001",
      title: "Japanese Professional Hair Scissors 440C",
      price: 49.99,
      image: "https://m.media-amazon.com/images/I/61IFXAQEs5L._AC_SL1500_.jpg",
      description: "Premium quality Japanese steel hair scissors for professional use.",
      rating: 4.8
    },
    {
      id: 6,
      sku: "SC-002",
      title: "High Carbon Hair Cutting Scissors",
      price: 15.99,
      image: "https://m.media-amazon.com/images/I/41vlSQUdyDL._AC_.jpg",
      description: "Durable high carbon scissors ideal for both professional and home use.",
      rating: 4.3
    },
    {
      id: 7,
      sku: "SC-003",
      title: "Hair Cutting Scissors",
      price: 15.99,
      image: "https://m.media-amazon.com/images/I/61c6CeD-E3L._AC_SL1500_.jpg",
      description: "Versatile scissors for hair cutting, crafted for precision.",
      rating: 4.5
    },
    {
      id: 8,
      sku: "FM-001",
      title: "Face Masks (Set of 3)",
      price: 9.99,
      image: "https://m.media-amazon.com/images/I/81kf3AFhDrL._AC_SX679_.jpg",
      description: "Comfortable and breathable face masks, set of 3. Essential for health and safety.",
      rating: 4.7
    }
  ];
  

const ProductSection = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Handler to show modal with product details
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  // Handler to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  return (
    <div className="products-section">
      <h2>Featured Products</h2>
      <div className="row">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} onImageClick={() => handleProductClick(product)} />
        ))}
      </div>
      {showModal && <ProductModal showModal={showModal} product={selectedProduct} onClose={handleCloseModal} />}
    </div>
  );
};

export default ProductSection;
