import React from 'react';
import './spinner.css';

const Spinner = () => (
  <div className="spinner-backdrop">
    <div className="spinner" aria-label="Loading"></div>
  </div>
);

export default Spinner;
